import SelectAssuntoTema from "@/components/Admin/SelectAssuntoTema/SelectAssuntoTema.vue";

export default {
    name: "admin-lista-conteudo",
    components: {
        SelectAssuntoTema
    },
    data() {
        return {
            arConteudos: [],
            blLoading: true,
            obForm: {
                titulo: null,
                id_assunto: null,
                id_tema: null
            },
            obFormPesquisa: {
                titulo: null,
                id_assunto: null,
                id_tema: null
            }
        };
    },
    created() {
        this.listar();
    },
    methods: {
        listar() {
            this.$root.$api.get("conteudo/listar").then(response => {
                this.arConteudos = response.retorno;

                this.blLoading = false;
            });
        },
        filtrar() {
            this.obFormPesquisa.titulo = this.obForm.titulo;
            this.obFormPesquisa.id_assunto = this.obForm.id_assunto;
            this.obFormPesquisa.id_tema = this.obForm.id_tema;
        },
        visualizar(obConteudo) {
            let route = null;

            if (obConteudo.rascunho == 1) {
                route = this.$router.resolve({
                    name: "Tema",
                    params: {
                        slug: obConteudo.tema.slug,
                        idConteudo: obConteudo.id
                    }
                });
            } else {
                route = this.$router.resolve({
                    name: "Tema",
                    params: { slug: obConteudo.tema.slug }
                });
            }

            window.open(route.href, "_blank");
        }
    },
    computed: {
        listaConteudo() {
            if (!this.arConteudos) {
                return [];
            }

            return this.arConteudos.filter(obConteudo => {
                return (
                    (!this.obFormPesquisa.titulo ||
                        obConteudo.titulo
                            .toLowerCase()
                            .indexOf(
                                this.obFormPesquisa.titulo.toLowerCase()
                            ) >= 0) &&
                    (!this.obFormPesquisa.id_tema ||
                        obConteudo.id_tema == this.obFormPesquisa.id_tema) &&
                    (!this.obFormPesquisa.id_assunto ||
                        obConteudo.tema.id_assunto ==
                            this.obFormPesquisa.id_assunto)
                );
            });
        }
    }
};
